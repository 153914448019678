import Splide from '@splidejs/splide';

let heroSlider = document.getElementsByClassName('hero-slider-image');
let heroSliderContent = document.getElementsByClassName('hero-slider');

document.addEventListener('DOMContentLoaded', function () {
    if (heroSlider.length > 0) {
        for (let i = 0; i < heroSlider.length; i++) {
            let splide = new Splide(heroSlider[i], {
                type: 'slide',
                perPage: 1,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: true,
                width: '100%'
            });

            let content = new Splide(heroSliderContent[i], {
                type: 'slide',
                perPage: 1,
                perMove: 1,
                rewind: true,
                pagination: false,
                arrows: false,
                width: '100%'
            });

            let bar = splide.root.querySelector('.splide-progress-bar');
            let indicatorCurrent = splide.root.querySelector('.splide-indicator .current');
            let indicatorTotal= splide.root.querySelector('.splide-indicator .total');

            splide.on('mounted move', function () {
                let end = splide.Components.Controller.getEnd() + 1;
                bar.style.width = String(100 * (splide.index + 1) / end) + '%';
                indicatorCurrent.innerHTML = splide.index + 1;
                indicatorTotal.innerHTML = end;
            });

            splide.sync(content)
            splide.mount();
            content.mount();
        }
    }
});


